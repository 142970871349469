



















































































































































































































































































































































































































































































































































































































import workspaceModule from '@/store/modules/workspaceModule';
import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import fuzzysort from 'fuzzysort';
import tasqsListModule from '@/store/modules/tasqsListModule';
import Multiselect from 'vue-multiselect';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import { sleep } from '@/utils/helpers';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';

@Component({
  components: {
    // AppLayout: () => getComponent('common/AppLayout'),
    // TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    // TasqSignal: () => getComponent('tasqs/TasqSignal'),
    // SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    // TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    // TasqItemKanban: () => getComponent('tasqs/TasqItemKanban'),
    // TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Multiselect,
  },
})
export default class Dropdown extends Vue {
//   @Model('change', { type: Array }) readonly dropdownValues!: any
//   @Prop({ type: Array, default: [] }) options!: any;
//   @Prop({ type: Boolean, default: false }) multiselect!: boolean;
//   @Prop({ type: Array, default: [] }) selectionLocation!: any;
//   @Prop({ type: String, default: '' }) trackBy!: string;
//   @Prop({ type: String, default: '' }) label!: string;
	checklistTemporaryText = ''
	searchDropdownInput = ''
	addToScheduleScheduleSelected = null
	addJobGroupSelected = ''
	refreshResponseDataKey = 0
	addToScheduleIsLoading = false
	addJobResponseData = {}
	addJobChecklistPopup = false
	addJobIsLoading = false
	isEditing = false

  created() {
	//   workspaceModule.getWorkspaces(null)
  }



	get availableUsers() {
		return accountModule.reassignmentList.map((i) => ({
		// @ts-ignore
		text: i.name,
		value: i.email,
		}));
	}

	get enabledWells() {
		return assetsModule.enabledWells;
	}

  get groupNames() {
      var results = []
      if (workspaceModule.activeTable == null) {
          return results
      }
      // @ts-ignore
      for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
          // @ts-ignore
          results.push(workspaceModule.activeTable.Groups[x].GroupName)
      }
      return results
  }

    get activeTasq() {

		if (this.isEditing || tasqsListModule.checkedTasqs.length ) {
			if(!tasqsListModule.activeTasq) {
				tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
			}
		}

		if (this.$route.query.type == 'id' && this.$route.params.id != null) {
			return tasqsListModule.activeTasq;
		}
		// @ts-ignore
		if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level == 'PAD') {
			return tasqsListModule.activeTasq;
		}
		if (tasqsListModule.activeTasq) {
			return tasqsListModule.activeTasq;
		}
		return assetsModule.activeTasq;
	}


  get scheduleNames() {
      var results: any[] = []
      if (workspaceModule.activeTables == null) {
          return results
      }
      // @ts-ignore
      for (var x = 0; x < workspaceModule.activeTables.length; x++) {
          // @ts-ignore
          results.push({
              value: workspaceModule.activeTables[x].ID,
              text: workspaceModule.activeTables[x].TableTitle,
          })
      }
      return results
  }

//   get activeTasq() {
//     return tasqsListModule.activeTasq;
//   }


  addJobShowChecklistPopup() {
      this.addJobChecklistPopup = true
  }

  addJobHideChecklistPopup() {
      this.addJobChecklistPopup = false
  }



  getColumnTypeIcon(column_type) {
      if (column_type == "WELL") {
          return "task"
      } else if (column_type == "USER") {
          return "person"
      } else if (column_type == "VENDOR") {
          return "engineering"
      } else if (column_type == "CHECKLIST") {
          return "checklist"
      } else if (column_type == "FILE") {
          return "picture_as_pdf"
      } else if (column_type == "TESTERS") {
          return "quiz"
      } else if (column_type == "TASQ_DATE_RANGE") {
          return "date_range"
      } else if (column_type == "COST") {
          return "attach_money"
      } else if (column_type == "TAGS") {
          return "tag"
      } else if (column_type == "TEXT") {
          return "format_size"
      } else if (column_type == "DATE") {
          return "event"
      } else if (column_type == "START_DATE") {
          return "event"
      } else if (column_type == "DUE_DATE") {
          return "event"
      } else if (column_type == "PROCEDURE") {
          return "task"
      } else if (column_type == "EMAIL") {
          return "email"
      } else if (column_type == "PHONE_NUMBER") {
          return "phone"
      } else if (column_type == "TITLE") {
          return "title"
      } else if (column_type == "TITLE") {
          return "title"
      } else {
          return "task"
      }
  }


  addJobColumns() {
      if (this.addJobGroupSelected == '') {
          return
      }
      if (workspaceModule.activeTable == null) {
          return
      }
    var groupSelected;
    // @ts-ignore
      for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
          // @ts-ignore
          if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
              // @ts-ignore
              groupSelected = workspaceModule.activeTable.Groups[x]
          }
      }

      var columns = groupSelected.Columns
      return columns

  }




    addJobRemoveChecklistItem(customName, checklistIndex) {
        this.addJobResponseData[customName].Response.splice(checklistIndex, 1);
        this.refreshResponseDataKey = this.refreshResponseDataKey + 1
    }

  closeDialog() {
    // console.log('close dialog');
	this.$emit('did-close-dialog')
  }

  addToScheduleHideClicked() {
      this.closeDialog()
  }

    // addToScheduleSelectedSchedule = null

    addToScheduleDidSelectSchedule() {
        // @ts-ignore
        workspaceModule.setActiveTable({id: this.addToScheduleScheduleSelected})
    }



  addJobDidEnterChecklistItem(customName, e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
        var newChecklist = {
			// @ts-ignore
            value: this.checklistTemporaryText,
            checked: false
        }
            // Do something
            this.addJobResponseData[customName].Response = [newChecklist].concat(this.addJobResponseData[customName].Response)
            // @ts-ignore
			this.checklistTemporaryText = ''
    }
  }


  async addJob() {
      this.addToScheduleIsLoading = true
        this.addJobIsLoading = true
        var groupSelected = null
        // @ts-ignore
        for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
            // @ts-ignore
            if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
                // @ts-ignore
                groupSelected = workspaceModule.activeTable.Groups[x]
            }
        }

        var columns: any[] = []
        for (const [key, value] of Object.entries(this.addJobResponseData)) {
            // @ts-ignore
            if (value["RequiredOnJobCreation"]) {

                // @ts-ignore
                if (value["Response"] == '' || value["Response"] == null || value["Response"] == undefined || value["Response"] == [] || value["Response"] == {}) {
                    console.log("[x] Did not fill out all required fields")

                    this.addToScheduleIsLoading = false
                    this.addJobIsLoading = false
                    return
                }
            }
            // @ts-ignore
            if (value["ColumnType"] == "STATUS") {
                // @ts-ignore
                value["Response"] = "Scheduled"
            }
            // @ts-ignore
            if (value["ColumnType"] == "WELL") {
                // @ts-ignore
                // @ts-ignore
                if (value["Response"]["value"] != null) {
                    // @ts-ignore
                    value["Response"] = value["Response"]["value"]
                } else {

                }
            }
            columns.push(value)
        }

		if(tasqsListModule.isActionUnReleated && tasqsListModule.checkedTasqs){
			tasqsListModule.checkedTasqs.forEach(async(tasq) => {
					 await scheduleModule.postNewGroupItem({
            username: accountModule.user.email.toLowerCase(),
            // @ts-ignore
            workspace_id: workspaceModule.activeWorkspace.ID,
            // @ts-ignore
            table_id: workspaceModule.activeTable.ID,
            // @ts-ignore
            group_id: groupSelected.ID,
            columns: JSON.stringify(columns),
			// @ts-ignore
            prediction_id: tasq.id
        })
			});

	    await sleep(1500)
		tasqsListModule.setIsActionUnReleated(false)
		tasqsListModule.resetChekedTasqs()
		}else{
			 await scheduleModule.postNewGroupItem({
            username: accountModule.user.email.toLowerCase(),
            // @ts-ignore
            workspace_id: workspaceModule.activeWorkspace.ID,
            // @ts-ignore
            table_id: workspaceModule.activeTable.ID,
            // @ts-ignore
            group_id: groupSelected.ID,
            columns: JSON.stringify(columns),
			// @ts-ignore
            prediction_id: this.activeTasq.id
        })
		}

        this.addJobIsLoading = false
        this.$emit('did-close-dialog')
        window.location.reload();
        this.addToScheduleIsLoading = false
    }

    addToScheduleDidSelectGroup() {
        this.addToScheduleSetupPage()

    }



    addJobRefreshTable() {
        this.refreshResponseDataKey = this.refreshResponseDataKey + 1
    }

    addToScheduleSetupPage() {
        var columns = this.addJobColumns()
        // @ts-ignore
        for (var x = 0; x < columns.length; x++) {
            // @ts-ignore
            if (columns[x].ColumnType == "WELL") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: {text: this.activeTasq!.wellName, value: this.activeTasq!.wellName}
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "USER") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: this.activeTasq!.username
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "VENDOR") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: {
                        "PhoneNumber": '',
                        "Email": '',
                        "FullName": ''
                    }
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "CHECKLIST") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: []
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "FILE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: null
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "TESTERS") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: []
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "TASQ_DATE_RANGE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: []
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "COST") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "TAGS") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: []
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "TEXT") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "DATE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "START_DATE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "DUE_DATE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType == "PROCEDURE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: null
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "EMAIL") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "PHONE_NUMBER") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            } else {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            }
        }
        this.refreshResponseDataKey = this.refreshResponseDataKey + 1

    }



}
